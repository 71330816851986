<template>
  <div />
</template>

<script>
import { auth } from '@/services/firebase'

export default {
  name: 'Logout',
  async created() {
    // Always will be a vue reload after signout due to main.js auth listener
    if (auth.currentUser) await this.$store.dispatch('user/logout')
    // So after the reload this component will be loaded again with no user signed in
    else this.$router.push({ name: 'login' })
  },
}
</script>
